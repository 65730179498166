/* Eric Meyer's Reset */

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent !important;
}

/* HTML5 display-role reset for older browsers */

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
  font-size: $base-font;
  font-family: $font-stack;
  color: $color-black;
}

ol,
ul {
  list-style: none;
}

/* Global Typography */

*,
:before,
:after {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  font-size: 62.5%;
}

/* Global Headings */

h1 {
  font-size: $base-font + 1;
  font-weight: 500;
  text-align: center;
  margin-top: 20px;
}

h2 {
  font-size: $base-font + 0.2;
  color: $color-white;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 25px;
  text-align: center;
  margin-bottom: 13px;
  letter-spacing: 0.5px;
}

h3 {
  color: $color-white;
  font-size: $base-font + 0.5;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 27px;
  margin-bottom: 8px;
}

/* General Text Formatting */

p {
  color: $paragraph-color;
  font-size: $base-font - 0.1;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 18px;
  margin-bottom: 12px;
}

small {
  font-size: 85.71429%;
  line-height: 1.75;
  display: inline-block;
}

img {
  max-width: 100%;
  height: auto;
}

input {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent !important;
  &:focus {
    background: #fff;
  }
  & div {
    -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE 10 and IE 11 */
    user-select: none;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 1000px white inset !important;
  -webkit-box-shadow: 0 0 0 1000px white inset !important;
  background-color: rgb(255, 255, 255) !important;
  transition: background-color 5000s ease-in-out 0s !important;
}

/* Global Link style */

a {
  color: $color-black;
  text-decoration: none;
  outline: none;
  display: inline-block;
}

/* Global Button Styles */

button {
  background: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  &:focus {
    outline: 0;
  }
}

.next-btn {
  width: 100%;
  @include border-radius(4px);
  //background-color: $btn-black;
  background-color: #ffd865;
  //color: $color-white;
  color: #333;
  font-size: $base-font;
  font-weight: bold;
  padding: 15px 38px;
}

.black-btn {
  @include border-radius(4px);
  //background-color: $btn-black;
  background-color: #ffd865;
  //color: $color-white;
  color: #333;
  font-size: $base-font;
  font-weight: bold;
  padding: 15px 38px;
}

.black-btn-disabled {
  @include border-radius(4px);
  //background-color: $btn-black;
  background-color: #f4f4f7;

  //color: $color-white;
  color: #8e8e8e;
  font-size: $base-font;
  font-weight: bold;
  padding: 15px 38px;
}

.retry-btn {
  @include border-radius(4px);
  background-color: $retry-bg;
  color: $otp-field-color;
  font-size: $base-font - 0.2;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18px;
  padding: 10.7px 32.7px;
}

// Global Classes
.d-flex {
  display: flex;
}

.align-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-space-between {
  justify-content: space-between;
}

.text-center {
  text-align: center;
}

/* Global style */

.container {
  max-width: 1183px;
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px;
}

.aligning-middle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: calc(100vh - 60px);
}

input {
  &:focus {
    outline: 0;
  }
}

svg {
  max-width: 100%;
  max-height: 100%;
  display: block;
}

// remove the dropdown when type=number in a html tag
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

//Responsive font size
@media only screen and (min-width: 375px) {
  html {
    font-size: 75.5%;
  }
}

@media only screen and (min-width: 414px) {
  html {
    font-size: 85.5%;
  }
  .tap-text strong {
    font-size: $base-font;
  }
}

@media only screen and (min-width: 470px) {
  html {
    font-size: 90.5%;
  }
}

@media only screen and (min-width: 540px) {
  html {
    font-size: 95.5%;
  }
}
