@import "../../common/utils/scss/main.scss";
.message-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 5px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.hamburger {
  width: 11px;
  // span {
  //     border: 0.5px solid $color-white;
  //     height: 1px;
  //     background: $color-white;
  //     opacity: 0.4;
  //     display: block;
  //     margin-bottom: 2px;
  //     @include border-radius(3px);
  //     &:last-child {
  //         margin-bottom: 0;
  //     }
  // }
  // .small-stick {
  //     width: 9px;
  // }
}

.logo-img {
  svg g {
    fill: $logo-color !important;
  }
}

.circle-message-wrap {
  height: 61px;
  width: 61px;
  display: flex;
  align-items: center;
  justify-content: center;
  @include border-radius(50%);
  background: $circle-bg;
  .phone-img {
    svg {
      fill: $color-white;
      opacity: 0.45;
    }
  }
}

.contact {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: "100px";
  padding-left: "20px";
  width: "100px";
}

.contact-chat {
  padding-left: "10px";
}
.contact-phone {
  padding-left: "5px";
}
