@import "../../common/utils/scss/main.scss";
.phone-field {
  width: 91.8%;
  margin: 0 auto;
  padding-top: 70px;
  .phone-title {
    color: $light-grey;
    font-size: $base-font - 0.4;
    font-weight: 500;
    letter-spacing: 1.4px;
    line-height: 11px;
    text-transform: uppercase;
    display: block;
    margin-bottom: 11px;
  }
  .email-control {
    width: 100%;
    height: 44px;
    //color: $color-white;
    color: $otp-field-color;
    opacity: 1;
    -webkit-text-fill-color: $otp-field-color;
    font-size: $base-font + 0.1;
    font-weight: 500;
    letter-spacing: 2.83px;
    line-height: 19px;
    //background-color: $input-bg;
    background-color: $color-white;
    //border-color: $input-bg;
    //border-width: 2px;
    font-family: $font-stack;
    padding: 5px 5px 5px 5px;
    outline: 0;
    border: 1px solid #cacaca;
    //box-shadow: none;
    @include border-radius(4px);
  }
  caret-color: $cursor-color;
  .field-email-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    width: 100%;
    @include border-radius(4px);
    border-radius: 4px;
    box-shadow: 0 2px 43px 0 rgba(0, 0, 0, 0.2);
  }
}

.text-danger {
  padding-left: 5px;
  padding-top: 5px;
  font-weight: normal;
  color: red !important;
  font-size: small;
  transform: translateY(-15px);
  position: absolute;
}
