@import "../../common/utils/scss/main.scss";
.page-wrapper {
  position: relative;
  &.loader-generated {
    filter: blur(2px);
    -webkit-filter: blur(2px);
  }
}

.mobile-screen {
  overflow: hidden;
  .app-header {
    margin-bottom: 10px;
    .logo-image {
      width: 100px;
      height: 100px;
      display: block;
    }
  }
}
