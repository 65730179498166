@import "../../common/utils/scss/main.scss";
.page-wrapper {
  background: #ffffff;
  height: 100vh;
  padding: 13px 14px;
  overflow: hidden;
}

.checkin-screen {
  margin-top: -78px;
}

.checking-details {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  .arriving-details {
    margin-right: 44px;
  }
  .check-titles {
    color: #4d4d4d;
    font-size: $base-font - 0.2;
    font-weight: 500;
    letter-spacing: 0.9px;
    line-height: 1.5;
    text-transform: uppercase;
    text-align: center;
  }
  .timing-details {
    color: $normal-txt-color;
    font-size: $base-font - 0.2;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 1.5;
    text-align: center;
  }
}

.gradient-revolve {
  position: relative;
  width: 262px;
  height: 262px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tap-here-wrap {
  height: 262px;
  width: 262px;
  opacity: 0.2;
  margin: 0 auto;
  animation: gradient-rotate 5s linear 2s infinite;
  @include border-radius(50%);
  @include pos-abs(0, auto, auto, 0);
  transform: rotate(-24deg);
  background: linear-gradient(
    90deg,
    rgba(255, 216, 101, 0) 0%,
    rgba(255, 216, 101, 0.16850490196078427) 35%,
    rgba(255, 216, 101, 1) 100%
  );
}

@keyframes gradient-rotate {
  from {
    @include transform(rotate(0));
  }
  to {
    @include transform(rotate(360deg));
  }
}

.inner-circle {
  background-color: #fff;
  width: 195px;
  height: 195px;
  @include border-radius(50%);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
  position: relative;
  &:after {
    content: "";
    @include pos-abs(50%, auto, auto, 50%);
    @include transform(translate(-50%, -50%));
    height: 195px;
    width: 195px;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#7db9e0+0,b5dcdc+100 */
    background: #ffd865;
    /* Old browsers */
    background: -moz-linear-gradient(left, #ffd865 0%, #ffd865 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #ffd865 0%, #ffd865 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, #ffd865 0%, #ffd865 100%);
    z-index: 1;
    @include border-radius(50%);
    opacity: 0.5;
  }
  &:before {
    content: "";
    @include pos-abs(50%, auto, auto, 50%);
    height: 175px;
    width: 175px;
    background: #ffd865;
    @include border-radius(50%);
    @include transform(translate(-50%, -50%));
    z-index: 2;
  }
}

.tap-text {
  color: $tap-color;
  font-size: $base-font - 0.4;
  font-weight: 500;
  font-family: $font-stack;
  letter-spacing: 2px;
  line-height: 11px;
  text-align: center;
  display: block;
  text-transform: uppercase;
  position: relative;
  z-index: 4;
  strong {
    color: $color-black;
    font-size: $base-font + 0.3;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 25px;
    text-transform: none;
    display: block;
  }
}
