@import "src/app/common/utils/scss/main.scss";
.checkbox-container {
  display: inline-block;
  position: relative;
  padding-left: 35px;
  height: 26px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: transparent !important;
  input {
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    @include pos-abs(0, auto, auto, 0);
    &:checked {
      + .checkmark {
        &:after {
          display: block;
        }
      }
    }
  }
  .checkmark {
    @include pos-abs(0, auto, auto, 0);
    height: 26px;
    width: 26px;
    background-color: #343642;
    @include border-radius(4px);
    &:after {
      left: 10px;
      top: 7px;
      width: 6px;
      height: 11px;
      border: solid #fff;
      border-width: 0 3px 3px 0;
      @include transform(rotate(45deg));
    }
  }
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
