@import '../../common/utils/scss/main.scss';
.next-btn-wrap {
    text-align: right;
    display: flex;
    justify-content: flex-end;
    .gradient-revolve {
        width: 55px;
        height: 55px;
        margin: 0;
        &.disable {
            opacity: 0.4;
            pointer-events: none;
        }
        .tap-here-wrap {
            width: 55px;
            height: 55px;
            animation: none;
        }
        .inner-circle {
            width: 36px;
            height: 36px;
            border-width: 4px;
            box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0);
            &:after {
                height: 36px;
                width: 36px;
            }
            &:before {
                height: 30px;
                width: 30px;
            }
        }
    }
}