@import '../../common/utils/scss/main.scss';
.terms-wrapper {
    @include pos-abs(0, 0, auto, auto);
    height: 100vh;
    width: 87.5%;
    z-index: 4;
    background: linear-gradient(135deg, #2F2A2B 0%, #1E1F2C 100%);
    box-shadow: -6px 2px 17px 0 rgba(0, 0, 0, 0.5);
    padding: 29px 18px 18px 23px;
    @include transform(translateX(114%));
    @include transition(all 0.7s ease-in-out);
    h3 {
        margin-bottom: 15px;
    }
    .eula-terms {
        height: calc(100vh - 195px);
        overflow: auto;
        h4 {
            font-size: $base-font + 0.2;
            color: $color-white;
            font-weight: 600;
            margin-bottom: 15px;
        }
        h5 {
            font-size: $base-font;
            color: $color-white;
            font-weight: 500;
            margin-bottom: 10px;
        }
        ul {
            margin-bottom: 20px;
            li {
                color: $paragraph-color;
                list-style: disc;
                margin-bottom: 8px;
                position: relative;
                padding-left: 15px;
                &:after {
                    content: '';
                    @include pos-abs(50%, auto, auto, 0);
                    @include transform(translateY(-50%));
                    height: 5px;
                    width: 5px;
                    @include border-radius(50%);
                    background: $paragraph-color;
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
    &.active {
        @include transition(all 0.7s ease-in-out);
        @include transform(translateX(0px));
    }
}

.btn-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-top: 15px;
    .next-btn-wrap {
        margin-right: 18px;
    }
}

.dismiss-btn {
    .tap-here-wrap {
        background: linear-gradient(to left, #FF5454 0%, rgba(192, 85, 255, 0.99) 41%, rgba(191, 87, 255, 0.96) 52%, rgba(189, 92, 255, 0.9) 60%, rgba(187, 98, 255, 0.81) 68%, rgba(184, 106, 255, 0.71) 76%, rgba(181, 116, 255, 0.57) 83%, rgba(176, 127, 255, 0.42) 89%, rgba(172, 140, 255, 0.24) 96%, rgba(168, 151, 255, 0.1) 100%);
    }
}