@import "../../common/utils/scss/main.scss";
.error-details {
  @include pos-abs(auto, auto, 35%, 50%);
  @include transform(translateX(-50%));
  width: 100%;
  text-align: center;
  .incorrect {
    color: $error-color;
    font-size: $base-font - 0.4;
    font-weight: 500;
    letter-spacing: 1.11px;
    line-height: 13px;
    text-align: center;
    display: block;
    text-transform: uppercase;
  }
  .enter-number {
    color: $error-msg;
    font-family: Circular;
    font-size: $base-font - 0.3;
    font-weight: 500;
    letter-spacing: 1.11px;
    line-height: 13px;
    text-align: center;
  }
}
