.popup-content {
  width: 75% !important;
  padding: 3rem !important;
  border-radius: 4px;
  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    h2 {
      color: black;
    }
    button {
      margin-top: 1em;
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 736px) {
  .phone-field {
    .Dropdown-root {
      .Dropdown-control {
        height: 17vw;
      }
    }
  }

  .Dropdown-placeholder {
    font-size: 12px;
    font-weight: 600;
  }
  .Dropdown-menu .Dropdown-option {
    font-size: 14px;
    height: 55px;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .phone-field {
    .Dropdown-root {
      .Dropdown-control {
        height: 55px;
      }
    }
  }

  .Dropdown-placeholder {
    font-size: 11px;
  }
  .Dropdown-menu .Dropdown-option {
    font-size: 11px;
    height: 55px;
  }
}
