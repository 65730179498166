@import "../../common/utils/scss/main.scss";
.errorState-wrapper {
  text-align: center;
  margin-top: -60px;
  position: relative;
  z-index: 2;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.error-img {
  margin-bottom: 9px;
  img {
    height: 40px;
    width: 40px;
  }
}

.errorState-text {
  color: #4d4d4d;
  font-size: $base-font;
  letter-spacing: 0;
  line-height: 21px;
  text-align: center;
  margin-bottom: 15px;
  span {
    display: block;
  }
}
