@import "../../common/utils/scss/main.scss";
.otp-page-wrapper {
  width: 82.2%;
  margin: 140px auto;
  //padding: 200px 0px 0px 0px;
}

.time-remaining {
  color: $error-msg;
  font-size: $base-font - 0.3;
  font-weight: 600;
  letter-spacing: 1.11px;
  line-height: 13px;
  margin-bottom: 9px;
  .countdown-remaining {
    color: #000;
  }
}

.resend-btn {
  display: flex;
  align-items: center;
  @include border-radius(4px);
  background-color: #343642;
  color: #fff;
  font-weight: bold;
  padding: 15px 18px;
  margin-right: 20px;
  font-size: $base-font - 0.3;
  font-weight: 600;
  letter-spacing: 2px;
  line-height: 20px;
  text-align: center;
  opacity: 1;
}

.resend-btn-disabled {
  display: flex;
  align-items: center;
  @include border-radius(4px);
  background-color: #f4f4f7;
  color: #8e8e8e;
  font-weight: bold;
  padding: 15px 18px;
  margin-right: 20px;
  font-size: $base-font - 0.3;
  font-weight: 600;
  letter-spacing: 2px;
  line-height: 20px;
  text-align: center;
  opacity: 1;
}

.otp-session-wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
}

.react-confirm-alert-overlay {
  background: rgba(0, 0, 0, 0.8);
}

.react-confirm-alert-body {
  width: 280px;
  font-family: $font-stack;
  padding: 15px;
  font-size: $base-font;
  line-height: 1.3;
  text-align: center;
}

.react-confirm-alert-button-group {
  justify-content: center;
}
