@import "../../common/utils/scss/main.scss";
.phone-field {
  width: 91.8%;
  margin: 0 auto;
  padding-top: 70px;
  .phone-title {
    color: $light-grey;
    font-size: $base-font - 0.4;
    font-weight: 500;
    letter-spacing: 1.4px;
    line-height: 11px;
    text-transform: uppercase;
    display: block;
    margin-bottom: 11px;
  }
  .form-control {
    width: 100%;
    height: 44px;
    //color: $color-white;
    color: $otp-field-color;
    opacity: 1;
    -webkit-text-fill-color: $otp-field-color;
    font-size: $base-font + 0.1;
    font-weight: 500;
    letter-spacing: 2.83px;
    line-height: 19px;
    //background-color: $input-bg;
    background-color: $color-white;
    //border-color: $input-bg;
    //border-width: 2px;
    font-family: $font-stack;
    padding: 5px 5px 5px 53px;
    outline: 0;
    //box-shadow: none;
    @include border-radius(4px);
  }
  caret-color: $cursor-color;
  .field-btn-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    .react-tel-input {
      width: 100%;
      @include border-radius(4px);
      box-shadow: 0 2px 43px 0 rgba(0, 0, 0, 0.2);
      .country-list {
        width: 265px;
      }
      .flag-dropdown {
        border-color: $input-bg;
        //background: $flag-bg;
        background-color: $color-white;
        border-width: 2px;
        width: 45px;
        border: 0;
        margin: 2px;
        margin-right: 0;
      }
      .selected-flag {
        width: 37px;
        //background: $flag-bg;
        background-color: $color-white;
        &:focus {
          //background: $flag-bg;
          background-color: $color-white;
        }
        .arrow {
          left: 22px;
        }
      }
    }
  }
}
