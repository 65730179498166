@import '../../common/utils/scss/main.scss';
.loader-img {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    @include pos-abs(0, auto, auto, 0);
    &:before {
        content: '';
        @include pos-abs(0, auto, auto, 0);
        height: 100%;
        width: 100%;
        background: rgba(0, 0, 0, 0.6);
        z-index: 1;
    }
    img {
        position: relative;
        z-index: 2;
        max-width: 66px;
    }
}