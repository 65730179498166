@import "src/app/common/utils/scss/main.scss";
.hotel-selectbox-wrap {
  width: 100%;
  height: 44px;
  position: relative;
  margin-bottom: 37px;
}

.Dropdown-root {
  .Dropdown-control {
    //box-shadow: 0 2px 43px 0 rgba(0, 0, 0, 0.81);
    box-shadow: 0 2px 43px 0 rgba(0, 0, 0, 0.2);
    background-color: $color-white;
    @include border-radius(4px);
    height: 44px;
    //padding: 11px 52px 11px 10px;
    padding: 11px 52px 11px 10px;
    color: $color-black;
    font-size: $base-font + 0.1;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 23px;
    //border: 0;
    border: 1px solid #cacaca;
  }
  &.is-open {
    .Dropdown-control {
      background-color: $color-white;
      color: $dropdown-color;
      border: 0;
    }
    .Dropdown-arrow {
      border: solid $dropdown-color;
      border-width: 0px 3px 3px 0;
      @include transform(rotate(-135deg));
      @include transition(all 0.5s ease-in-out);
      top: 19px;
    }
  }
}

.Dropdown-menu {
  box-shadow: 0 32px 43px 0 rgba(0, 0, 0, 0.1);
  border: 0;
}

.Dropdown-option {
  height: 46px;
  //color: $dropdown-color;
  font-size: $base-font + 0.1;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 23px;
  padding: 11px 10px;
  &:last-child {
    border-radius: 0 0 4px 4px;
  }
  &.is-selected {
    -webkit-tap-highlight-color: #424242 !important;
    color: $color-white;
    //background: $dropdown-options-bg;
    background: linear-gradient(45deg, #424242, #393939);
  }
}

.Dropdown-arrow {
  //border: solid $color-white;
  border: solid $dropdown-color;
  border-width: 0px 3px 3px 0;
  //border-width: 0px 2.3px 2.3px 0;
  width: 7.5px;
  height: 7.5px;
  top: 16px;
  right: 14px;
  @include transform(rotate(45deg));
  background: transparent;
}
