//@import "../../common/utils/scss/main.scss";
//.FormGroup {
//  margin: 0 15px 20px;
//  padding: 0;
//  border-style: none;
//  will-change: opacity, transform;
//  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
//    inset 0 1px 0 #829fff;
//  border-radius: 4px;
//}
//
//.FormRow {
//  display: -ms-flexbox;
//  display: flex;
//  -ms-flex-align: center;
//  align-items: center;
//  margin-left: 15px;
//  border-top: 1px solid #819efc;
//}
//
//.FormRow:first-child {
//  border-top: none;
//}
//
//.FormRowLabel {
//  width: 15%;
//  min-width: 70px;
//  padding: 11px 0;
//  color: #c0c0c0;
//  overflow: hidden;
//  text-overflow: ellipsis;
//  white-space: nowrap;
//}
//
//@keyframes void-animation-out {
//  0%,
//  to {
//    opacity: 1;
//  }
//}
//.FormRowInput:-webkit-autofill {
//  -webkit-text-fill-color: #fce883;
//  /* Hack to hide the default webkit autofill */
//  transition: background-color 100000000s;
//  animation: 1ms void-animation-out;
//}
//
//.FormRowInput {
//  font-size: 16px;
//  width: 100%;
//  padding: 11px 15px 11px 0;
//  color: #000;
//  background-color: transparent;
//  animation: 1ms void-animation-out;
//}
//
//.FormRowInput::placeholder {
//  color: #000000;
//}
//
//.StripeElement--webkit-autofill {
//  background: transparent !important;
//}
//
//.StripeElement {
//  width: 100%;
//  padding: 11px 15px 11px 0;
//}
//
//.SubmitButton {
//  display: block;
//  font-size: 16px;
//  width: calc(100% - 30px);
//  height: 40px;
//  margin: 5px 15px 0;
//  background-color: #c0c0c0;
//  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
//    inset 0 1px 0 #c0c0c0;
//  border-radius: 4px;
//  color: #fff;
//  font-weight: 600;
//  cursor: pointer;
//  transition: all 100ms ease-in-out;
//  will-change: transform, background-color, box-shadow;
//}
//
//.SubmitButton:active {
//  background-color: #c0c0c0;
//  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
//    inset 0 1px 0 #c0c0c0;
//  transform: scale(0.99);
//}
//
//.SubmitButton.SubmitButton--error {
//  transform: translateY(15px);
//}
//.SubmitButton.SubmitButton--error:active {
//  transform: scale(0.99) translateY(15px);
//}
//
//.SubmitButton:disabled {
//  opacity: 0.5;
//  cursor: default;
//  background-color: #7795f8;
//  box-shadow: none;
//}
//
//.ErrorMessage {
//  color: #000;
//  display: flex;
//  justify-content: center;
//  padding: 0 15px;
//  font-size: 13px;
//  margin-top: 0px;
//  width: 100%;
//  transform: translateY(-15px);
//  opacity: 0;
//  animation: fade 150ms ease-out;
//  animation-delay: 50ms;
//  animation-fill-mode: forwards;
//  will-change: opacity, transform;
//}
//
//.ErrorMessage svg {
//  margin-right: 10px;
//}
//
//.Result {
//  margin-top: 50px;
//  text-align: center;
//  animation: fade 200ms ease-out;
//}
//
//.ResultTitle {
//  color: #fff;
//  font-weight: 500;
//  margin-bottom: 8px;
//  font-size: 17px;
//  text-align: center;
//}
//
//.ResultMessage {
//  color: #9cdbff;
//  font-size: 14px;
//  font-weight: 400;
//  margin-bottom: 25px;
//  line-height: 1.6em;
//  text-align: center;
//}
//
//.ResetButton {
//  border: 0;
//  cursor: pointer;
//  background: transparent;
//}
.FormRowInput .img {
  background-color: #000 !important;
}
#__lpform_cardnumber_icon {
  background-color: #000 !important;
}
