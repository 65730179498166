$breakpoints: (
  mobile: 768px,
  tablet: 1024px,
  smalldesktop: 1200px,
);
$font-stack: "Poppins", sans-serif;
$base-font: 1.3rem;
$font-color: blue;
$color-black: #000000;
$color-white: #ffffff;
$label-color: #4e4e4e;
$border-color: #434343;
$btn-black: #1c191a;
$gradient-dark-bg: #2f2a2b;
$gradient-light-bg: #1e1f2c;
$circle-bg: #343642;
$tap-color: #1d1d1d;
$details-color: #c0c0c0;
$normal-txt-color: #7e7e7e;
$logo-color: #d8d8d8;
$light-grey: #919191;
$cursor-color: #4798ff;
$error-color: #ff5656;
$error-msg: #7e7c80;
$input-bg: #36333e;
$flag-bg: #27242b;
$otp-field-color: #333333;
$retry-bg: #ffd865;
$select-box-bg: #36333e;
$select-text: #b9b8b8;
$upload-border: #c9c9c9;
$upload-text: #888888;
$progress-space-bg: #e6e6e6;
$progress-bar-color: #2bbd00;
$upload-error-color: #f35353;
$dropdown-color: #282828;
$dropdown-options-bg: #1f2e36;
$paragraph-color: #8d8d8e;
$success: #96ff81;
$notfound-bg: #f4f4f7;
$border-gradient: linear-gradient(
  to right,
  rgba(125, 185, 224, 1) 0%,
  rgba(181, 220, 220, 1) 100%
);
$hold-btn-bg: #ffd865;
$hold-btn-disabled-bg: #ffebad;
$hold-form-field-color: #ffffff;
$hold-form-field-bg: #36333e;
$hold-form-field-placeholder-color: #ebebeb;
$form-field-border: #44404f;
