@import "../../common/utils/scss/main.scss";
.success-checkin-wrap {
  text-align: center;
  width: 87%;
  margin: 0 auto;
  margin-top: 120px;
  h3 {
    margin-bottom: 45px;
  }
}

.success-checkin {
  h4 {
    color: #fbc112;
    font-size: $base-font + 0.1;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 1.4;
    text-align: center;
    margin-bottom: 10px;
    span {
      display: block;
    }
  }
  p {
    color: $light-grey;
    font-weight: 400;
    font-size: $base-font - 0.1;
    letter-spacing: 0;
    line-height: 1.4;
  }
}
