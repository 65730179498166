@import "src/app/common/utils/scss/main.scss";
.upload-form-wrap {
  width: 75.7%;
  margin: 0 auto;
}

.upload-page-wrapper {
  margin-top: -78px;
  h2 {
    color: black;
  }
}

.upload-doc-wrapper {
  width: 100%;
  margin: 0 auto;
  margin-bottom: 20px;
}

.upload-cancel-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  .upload-title {
    margin-bottom: 0;
  }
}

.upload-title {
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 8px;
  padding-right: 0 !important;
}

.upload-title,
.cancel-btn,
.edit-btn {
  color: $light-grey;
  font-size: $base-font - 0.5;
  font-weight: 500;
  letter-spacing: 2px;
  line-height: 1.5;
  display: inline-block;
  font-family: $font-stack;
}

.cancel-btn,
.edit-btn {
  color: #ffffff;
  text-transform: uppercase;
  display: none;
}

.edit-btn {
  display: block;
}

// checkbox division styles
.checkbox-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 25px;
}

.about-agreement {
  color: $error-msg;
  font-size: $base-font - 0.4;
  font-weight: 600;
  letter-spacing: 1.11px;
  line-height: 1.5;
  .user-terms {
    color: $color-black;
    text-transform: uppercase;
    font-family: $font-stack;
    font-size: $base-font - 0.3;
    letter-spacing: 1.11px;
  }
}
