@import "../../common/utils/scss/main.scss";
.upload-page-wrapper {
  h2 {
    line-height: 1.5;
  }
}

.upload-documents-wrapper {
  background: $color-white;
  //height: 84px;
  width: 100%;
  border: 1px dashed $upload-border;
  @include border-radius(4px);
  //box-shadow: 0 2px 43px 0 rgba(0, 0, 0, 0.81);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 10px;
  input {
    @include pos-abs(0, auto, auto, 0);
    width: 100%;
    height: 100%;
    opacity: 0;
  }
  &.disabled-tap {
    pointer-events: none;
  }
  &.disabled {
    opacity: 0.4;
    pointer-events: none;
  }
}

.uploaded-img {
  //It is for image preview
  height: 82.15px;
  width: 100%;
  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
  small {
    width: 100%;
    text-align: center;
    font-size: 0.8rem;
    transform: translateY(-15px);
    img {
      width: 0.8rem;
      transform: translate(-1px, 3px);
    }
  }
}

.upload-text {
  color: $upload-text;
  font-size: $base-font - 0.4;
  font-weight: 300;
  letter-spacing: 0.43px;
  line-height: 16px;
  text-align: center;
  .files-needed {
    display: block;
    text-align: center;
  }
}

.input-upload {
  display: none;
}

.landscapeImage {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 96px;
  height: 96px;
}

.button-upload-pre {
  cursor: pointer;
  margin-top: 20px;
}

.progress-bar-wrap {
  //Loader for image on serve
  width: 100%;
  margin-top: 10px;
  .progress-bar-space {
    height: 3px;
    background: $progress-space-bg;
    @include border-radius(15px);
    .progressBar-width {
      background: $progress-bar-color;
      display: block;
      height: 3px;
      @include border-radius(15px);
    }
  }
  .remaining-data {
    color: $upload-text;
    font-size: $base-font - 0.4;
    font-weight: 300;
    letter-spacing: 0.43px;
    line-height: 16px;
  }
}

.uploadError-state-wrap {
  width: 100%;
  .error-text {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $upload-error-color;
    font-size: $base-font - 0.4;
    font-weight: 300;
    letter-spacing: 0.43px;
    line-height: 16px;
    margin-bottom: 6px;
    .error-icon {
      margin-right: 3px;
      display: block;
    }
  }
  .retry-upload {
    color: $otp-field-color;
    font-size: $base-font - 0.5;
    font-weight: 600;
    letter-spacing: 2px;
    line-height: 11px;
    text-transform: uppercase;
    text-align: center;
  }
}

.upload-cancel-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  .upload-title {
    margin-bottom: 0;
  }
}

.upload-title {
  text-transform: uppercase;
  margin-bottom: 6px;
  padding-right: 61px;
}

.upload-title,
.cancel-btn,
.edit-btn {
  color: $light-grey;
  font-size: $base-font - 5;
  font-weight: 500;
  letter-spacing: 2px;
  line-height: 11px;
  display: inline-block;
}

@media only screen and (min-width: 414px) {
  .upload-page-wrapper {
    h2 {
      margin-bottom: 30px;
    }
  }
  .Dropdown-root {
    .Dropdown-control {
      height: 50px;
      padding: 13px 52px 13px 10px;
    }
    .Dropdown-arrow {
      top: 20px;
    }
  }
  .upload-doc-wrapper {
    margin-bottom: 26px;
  }
  .upload-documents-wrapper {
    min-height: 135px;
  }
  .uploaded-img {
    height: 133.2px;
  }
}

@media only screen and (min-height: 812px) {
  .upload-page-wrapper {
    h2 {
      margin-bottom: 30px;
    }
  }
  .Dropdown-root {
    .Dropdown-control {
      height: 50px;
      padding: 13px 52px 13px 10px;
    }
    .Dropdown-arrow {
      top: 20px;
    }
  }
  .upload-doc-wrapper {
    margin-bottom: 26px;
  }
  .upload-documents-wrapper {
    min-height: 135px;
  }
  .uploaded-img {
    height: 133.2px;
  }
}
