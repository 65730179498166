@import "../../common/utils/scss/main.scss";
.otp-field {
  width: 100%;
  @include border-radius(4px);
  background-color: $color-white;

  box-shadow: 0 2px 43px 0 rgba(0, 0, 0, 0.2);

  margin-bottom: 26px;
  .input-style {
    width: 100% !important;
    height: 50px;
    margin: 0;
    border-collapse: collapse;
    padding: 0 !important;
    border: 0;
    border-right: 1px solid rgba(0, 0, 0, 0.08);
    color: $otp-field-color;
    font-size: $base-font + 0.2;
    font-weight: 600;
    font-family: $font-stack;
    letter-spacing: 2.83px;
    line-height: 19px;
    text-align: center;
    -webkit-border-radius: 0;
    border-radius: 0;
  }
  & > div {
    &:first-child {
      .input-style {
        border-radius: 4px 0 0 4px;
      }
    }
    &:last-child {
      .input-style {
        border-radius: 0 4px 4px 0;
        border-right: 0;
      }
    }
  }
  caret-color: $cursor-color;
}
